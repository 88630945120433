.root {
  display: inline-block;
  user-select: none;
  font-size: 0;
}

.switchChilds img:last-of-type {
  display: none;
}

*:focus .switchChilds img:first-of-type,
.switchChilds:hover img:first-of-type,
.switchChilds:focus img:first-of-type {
  display: none;
}

*:focus .switchChilds img:last-of-type,
.switchChilds:hover img:last-of-type,
.switchChilds:focus img:last-of-type {
  display: inline;
}
