.root {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    box-shadow: none;
    border: none;
    text-decoration: none;
  }

  *:hover,
  *:focus,
  *:active,
  *:visited {
    outline: none;
    box-shadow: none;
  }
}

.root {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  box-shadow: none;
  border: none;
  text-decoration: none;
}

.root:hover,
.root:focus,
.root:active,
.root:visited {
  outline: none;
  box-shadow: none;
}
